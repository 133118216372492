<template>
    <div>
        <b-row>
            <b-col sm="3" xs="12">
                <h1>Innehåll</h1>
            </b-col>
            <b-col sm="9" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary" class="mr-3" @click="copyArchive">
                    Kopiera
                </b-button>
                <b-button variant="primary" @click="add">Lägg till</b-button>
            </b-col>
            <b-col>
                <div>
                    <label for="list-archive--select-building">Välj fastighet</label>
                    <b-form-select id="list-archive--select-building"
                                   v-model="selectedBuilding"
                                   :options="allBuildings"
                                   @change="onBuildingChange"></b-form-select>
                </div>
                <hr />
                <b-form-checkbox v-model="filters.showSortOrder" class="mb-2">
                    Visa sorteringsordning
                </b-form-checkbox>

                <!--Parent-->
                <div v-for="parent in items.filter((x) => !x.parentId)"
                     :key="parent.id"
                     @click.stop="toggleOpen(parent)">
                    <ArchiveItem :item="parent"
                                 :show-sort-order="filters.showSortOrder"
                                 :show-type-description="true"
                                 @event="onArchiveItemEvent" />
                    <!--Child-->
                    <div v-for="child in items.filter((x) => x.parentId === parent.id)"
                         v-show="parent.isOpen"
                         :key="child.id"
                         @click.stop="toggleOpen(child)">
                        <ArchiveItem :item="child"
                                     :show-sort-order="filters.showSortOrder"
                                     margin-left="4"
                                     @event="onArchiveItemEvent" />
                        <!--SubChild-->
                        <div v-for="subChild in items.filter((x) => x.parentId === child.id)"
                             v-show="child.isOpen"
                             :key="subChild.id">
                            <ArchiveItem :item="subChild"
                                         :show-sort-order="filters.showSortOrder"
                                         margin-left="5"
                                         @event="onArchiveItemEvent" />
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <!--Save Sidebar-->
        <b-sidebar v-model="sidebar.visible"
                   backdrop-variant="dark"
                   backdrop
                   right
                   lazy
                   :width="
                       !sidebar.selected || sidebar.selected.type !== 0 ? '320px' : '640px'
                   "
                   shadow
                   :title="`${sidebar.selected ? 'Ändra' : 'Skapa'} innehåll`">
            <div class="px-2">
                <SaveArchive :id="sidebar.selected ? sidebar.selected.id : 0"
                             :buildingId="selectedBuilding"
                             :parent-id="sidebar.parentId"
                             @event="onArchiveItemEvent" />
            </div>
        </b-sidebar>

        <!--Copy Archive Sidebar-->
        <b-sidebar v-model="isCopySidebarVisible"
                   backdrop-variant="dark"
                   backdrop
                   right
                   lazy
                   shadow
                   title="Välj mall">
            <div class="px-2">
                <CopyArchive @event="onArchiveItemEvent"
                             :buildingId="selectedBuilding" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ArchiveItem from '@/components/archive/ArchiveItem';
    import SaveArchive from '@/components/archive/SaveArchive';
    import CopyArchive from '@/components/archive/CopyArchive';
    import { get } from '@/helpers/api';

    export default {
        name: 'ListArchive',
        components: {
            ArchiveItem,
            SaveArchive,
            CopyArchive
        },
        data() {
            return {
                items: [],
                allBuildings: [
                    { value: null, text: '-- Ingen --' },
                ],
                selectedBuilding: null,
                sidebar: {
                    selected: null,
                    parentId: null,
                    visible: false
                },
                filters: {
                    showSortOrder: false
                },
                isCopySidebarVisible: false
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user,
                selectedCustomer: (state) => state.user.selectedCustomer,
                selectedBuildingId: (state) => state.user.selectedBuildingId
            })
        },
        watch: {
            selectedCustomer(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.selectedBuilding = null;
                    this.getAllByCustomerId();
                }
            },
            selectedBuildingId(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.selectedBuilding = newValue;
                    this.getAllByBuildingId();
                }
            }
        },
        async mounted() {
            // Get and map if item has children.
            this.getAllByCustomerId();
            await this.getAllBuildings();
        },
        methods: {
            ...mapActions('user', ['setBuilding']),
            ...mapActions('archive', ['getMenu']),
            openSidebar(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
            closeSidebar() {
                this.sidebar = {
                    selected: null,
                    parentId: null,
                    visible: false
                };
            },
            copyArchive() {
                this.isCopySidebarVisible = true;
            },
            add() {
                this.openSidebar(null);
            },
            edit(item) {
                this.openSidebar(item);
            },
            onArchiveItemEvent(archiveEvent) {
                switch (archiveEvent.action) {
                    case 'add':
                        this.sidebar.parentId = archiveEvent.item.parentId;
                        return this.add();
                    case 'edit':
                        return this.edit(archiveEvent.item);
                    case 'copied':
                        this.getMenu();
                        this.getItems();
                        this.isCopySidebarVisible = false;
                        break;
                    case 'saved':
                        // Reload menu, then updates item and closes sidebar.
                        this.getMenu().then(() => {
                            // Updates.
                            this.getItems();
                            this.closeSidebar();
                        });
                        break;
                    case 'deleted':
                        // Reload menu, then updates item and closes sidebar.
                        this.getMenu().then(() => {
                            // Updates.
                            this.getItems();
                            this.closeSidebar();
                        });
                        break;
                }
            },
            toggleOpen(item) {
                item.isOpen = !item.isOpen;
            },
            getItems() {
                if (!this.selectedBuilding) {
                    return this.getAllByCustomerId();
                } else {
                    this.getAllByBuildingId();
                }
            },
            getAllByBuildingId() {
                get('Archive', `GetBuildingById?id=${this.selectedBuilding}`).then(x => {
                    this.items = x.data.map((y) => {
                        return {
                            ...y,
                            hasChildren: x.data.some((z) => z.parentId === y.id)
                        };
                    });
                });
            },
            getAllByCustomerId() {
                get('Archive', `GetAllByCustomerId?id=${this.selectedCustomer.id}`).then(
                    (x) => {
                        this.items = x.data.map((y) => {
                            return {
                                ...y,
                                hasChildren: x.data.some((z) => z.parentId === y.id)
                            };
                        });
                    }
                );
            },
            async onBuildingChange(val) {
                this.getItems();

                await this.setBuilding(val ?? null);
            },
            async getAllBuildings() {
                await get('Building', `GetAll/${this.selectedCustomer.id}`)
                    .then((x) => {
                        this.allBuildings = [
                            ...this.allBuildings,
                            ...x.data.map(x => ({
                                value: x.id,
                                text: x.name
                            }))
                        ];
                    })
                    .catch((x) => {
                        alert('Något gick snett vid hämtning av fastigheter.');
                    });
            }
        }
    };
</script>
