<template>
    <div>
        <div v-show="buildingId">
            <label for="list-archive--select-building">Från fastighet</label>
            <b-form-select id="list-archive--select-building"
                           v-model="selectedBuilding"
                           :options="allBuildings"
                           @change="onBuildingChange"></b-form-select>
        </div>
        <hr v-show="!selectedBuilding" />
        <div :class="{'mt-3': selectedBuilding}">
            <label v-show="!selectedBuilding">Från mall</label>
            <b-list-group v-for="(customer, index) in customers" :key="customer.id" :class="{'mt-3': index > 0}">
                <b-list-group-item role="button"
                                   @click="copy(customer.id, customer.name, customer.customerNo)"
                                   :disabled="buildingId || selectedBuilding">
                    <font-awesome-icon icon="address-book"></font-awesome-icon> {{ customer.name }} - {{ customer.customerNo }}
                </b-list-group-item>
                <b-list-group-item v-for="building in customer.buildings"
                                   :key="building.id"
                                   class="ml-4"
                                   @click="copyBuilding(building.id, building.name)"
                                   role="button">
                    <font-awesome-icon icon="building"></font-awesome-icon> {{ building.name }}
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>
<script>
    import { get, post } from '@/helpers/api';
    import { mapState } from 'vuex';
    import { ENTITY_TYPES } from "@/helpers/entity-type.helper";

    export default {
        name: 'CopyArchive',
        props: {
            buildingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                allBuildings: [
                    { value: null, text: '-- Ingen --' },
                ],
                selectedBuilding: null,
                customers: [],
            };
        },
        computed: {
            ...mapState({
                selectedCustomerId: (state) => state.user.selectedCustomer.id,
                selectedCustomerNo: (state) => state.user.selectedCustomer.customerNo,
                selectedCustomerName: (state) => state.user.selectedCustomer.name
            })
        },
        async mounted() {
            this.getData();
            await this.getAllBuildings();
        },
        methods: {
            getBuildingName(buildingId) {
                return this.allBuildings.find(x => x.value === buildingId).text;
            },
            async onBuildingChange() {
                this.getData();
            },
            async getAllBuildings() {
                await get('Building', `GetAll/${this.selectedCustomerId}`)
                    .then((x) => {
                        this.allBuildings = [
                            ...this.allBuildings,
                            ...x.data.map(x => ({
                                value: x.id,
                                text: x.name
                            }))
                        ];
                    })
                    .catch((x) => {
                        alert('Något gick snett vid hämtning av fastigheter.');
                    });
            },
            copy(id, customerName, customerNo) {
                this.$bvModal
                    .msgBoxConfirm(
                        `Är du säker på att du vill kopiera från ${customerName} (${customerNo}) till ${this.selectedCustomerName} (${this.selectedCustomerNo})?`,
                        {
                            title: '',
                            size: 'sm',
                            cancelVariant: 'danger',
                            cancelTitle: 'Nej',
                            okTitle: 'Ja',
                            okVariant: 'primary',
                            footerClass: 'p-2 justify-content-between',
                            hideHeaderClose: false,
                            centered: true
                        }
                    )
                    .then((confirmed) => {
                        if (confirmed) {
                            this.copyArchives(id, ENTITY_TYPES['customer'].id);
                            this.reset();
                        }
                    })
                    .catch(() => {
                        this.isSubmitting = true;
                    });
            },
            copyBuilding(id, buildingName) {
                this.$bvModal
                    .msgBoxConfirm(
                        `Är du säker på att du vill kopiera fastighet '${buildingName}' till kund ${this.selectedCustomerName} (${this.selectedCustomerNo}) och fastighet '${this.getBuildingName(this.buildingId)}'?`,
                        {
                            title: '',
                            size: 'sm',
                            cancelVariant: 'danger',
                            cancelTitle: 'Nej',
                            okTitle: 'Ja',
                            okVariant: 'primary',
                            footerClass: 'p-2 justify-content-between',
                            hideHeaderClose: false,
                            centered: true
                        }
                    )
                    .then((confirmed) => {
                        if (confirmed) {
                            this.copyArchives(id, ENTITY_TYPES['building'].id);
                            this.reset();
                        }
                    })
                    .catch(() => {
                        this.isSubmitting = true;
                    });
            },
            async copyArchives(id, entityType) {
                await post('Copy', 'CopyArchives', {
                    entityId: id,
                    entityType: entityType,
                    customerId: this.selectedCustomerId,
                    buildingId: this.buildingId,
                })
                    .then((x) => {
                        this.$emit('event', { action: 'copied' });
                    });
            },
            async getData() {
                if (this.selectedBuilding) {
                    await get('Copy', `GetBuildingWithNoTemplate/${this.selectedBuilding}`)
                        .then(x => {
                            this.customers = [x.data];
                        })
                        .catch((x) => {
                            alert('Något gick snett vid hämtning av fastighet.');
                        });

                    return;
                }

                await get('Copy', 'GetAllTemplate')
                    .then(x => this.customers = x.data);
            }
        }
    };
</script>

<style>
    .list-group-item:hover,
    .list-group-item:active,
    .list-group-item:focus {
        background-color: #efefef;
    }
</style>
